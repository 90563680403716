import * as FoActions from 'features/frontoffice/actions/actions';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { store } from 'store/store';
import * as DateUtil from 'utils/dateUtil';

const notAvailable = 'N/A';

const Inspection = () => {
  const inspection = useSelector((state) => state.fo.inspection);
  const currentTenantCd = useSelector((state) => state.user.currentTenantCd);

  useEffect(() => {
    store.dispatch(FoActions.getInspectionForTenant());
  }, [currentTenantCd]);

  return (
    <>
      <div className="site-main__top">
        <div className="row align-items-center mb-4">
          <div className="col-12 d-flex justify-content-end refresh-status">
            <p className="mr-4">
              <i className="fa fa-dot-circle green-text"></i>
              <span>
                Data available through:{' '}
                {inspection.refreshedAt
                  ? DateUtil.FORMAT(inspection.refreshedAt, 'MMM yyyy')
                  : notAvailable}
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Inspection;

import { AppConfig } from 'AppConfig';
import axios from 'axios';
import { CASE_STATUS, RENEWAL_ACCOUNT_TYPE } from 'common/Constant';
import { APPSTATE_SET_APP_ERROR } from 'store/actions/appstate';
import { FETCH_ANNUAL_PLAN_RENEWAL_LIST } from './types';

export const setAnnualPlanRenewalList = (payload) => ({ type: FETCH_ANNUAL_PLAN_RENEWAL_LIST, payload });

export const getAnnualPlanRenewalData = () => {
  return async (dispatch) => {
    try {
      const response = await axios.post(AppConfig.CLIENT_PORTAL_URL + `/annual-renewal/search`, {});
      if (response) {
        const annualPlanRenewalList = response.data?.filter((row) => row.renewalAccountType !== RENEWAL_ACCOUNT_TYPE.STATIC_CLIENT);
        dispatch(setAnnualPlanRenewalList(annualPlanRenewalList || []));
      }
    } catch (error) {
      dispatch({ type: APPSTATE_SET_APP_ERROR, payload: error });
    }
  };
};

export const updateRenewalCaseStatus = (id, caseStatus = CASE_STATUS.READY_TO_RENEW) => {
  return async (dispatch) => {
    try {
      const response = await axios.put(AppConfig.CLIENT_PORTAL_URL + `/case-management/update?id=${id}&status=${caseStatus}`);
      if (response) {
        dispatch(getAnnualPlanRenewalData());
      }
    } catch (error) {
      dispatch({ type: APPSTATE_SET_APP_ERROR, payload: error });
    }
  };
};

export const sendPoCOpsRequest = (firstName, lastName, email, tenantCode, operation) => {
  return async (dispatch) => {
    try {
      await axios.post(AppConfig.CLIENT_PORTAL_URL + `/annual-renewal/poc-ops`, {
        tenantCode: tenantCode,
        email: email,
        operation: operation,
        firstName: firstName,
        lastName: lastName
      });
    } catch (error) {
      dispatch({ type: APPSTATE_SET_APP_ERROR, payload: error });
    }
  };
};

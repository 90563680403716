import { AppConfig } from 'AppConfig';
import axios from 'axios';
import debounce from 'lodash.debounce';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { store } from 'store/store';
import { isEmpty } from 'utils/getObjectType';
import 'features/landing/navigation/top/bstBars.css';
import { Autocomplete } from '@material-ui/lab';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import {
  TextField,
  Box,
  Grid,
  IconButton,
  withStyles,
  AppBar
} from '@material-ui/core';
import { Help } from '@material-ui/icons';
import UserMenu from 'features/landing/navigation/top/userMenu';
import AppNavigator from 'features/landing/navigation/top/appNavigator';
import { fetchData } from 'features/user/userActions';
import * as UserTypes from 'features/user/userTypes';
import * as ReportTypes from 'features/frontoffice/actions/types';
import SearchIcon from '@material-ui/icons/Search';
import { trackingTypes, trackingActions, trackClick } from 'utils/tracking';
import Auth from 'auth/Auth';

const barBorder = '1px solid lightgrey !important';

const styles = {
  search: {
    minWidth: '200px',
    marginRight: '0.5rem',
    '& label': {
      transition: 'none'
    }
  },
  searchPanel: {
    borderLeft: barBorder,
    boxSizing: 'border-box',
    padding: '0.5rem',
    '& input + input': {
      marginLeft: '0.5rem'
    }
  },
  container: {
    borderBottom: barBorder,
    height: '70px'
  },
  logoMenu: {
    paddingLeft: '1rem',
    '& img': {
      width: '100%',
      objectFit: 'scale-down',
      paddingRight: '0.5rem',
      paddingLeft: '0.5rem',
      maxHeight: '40px'
    },
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  foSearch: {
    minWidth: '220px',
    border: 'none !important',
    boxShadow: 'none !important'
  },
  profilePanel: {
    position: 'relative',
    display: 'flex',
    flexDirectio: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'end',
    alignItems: 'center',
    alignSelf: 'center',
    height: '100%'
  },
  profileHelper: {
    borderRight: barBorder,
    display: 'flex',
    justifyContent: 'end'
  },
  menuHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    padding: '10px'
  },
  popover: {
    pointerEvents: 'none'
  }
};

class BstBars extends Component {
  state = {
    allBrokerUrl: null,
    allTenantUrl: null,
    allBrokers: [],
    allTenants: [],
    claimTypeOptions: null,
    currentTenants: [],
    isOpen: false,
    messages: {},
    planYears: [],
    processingEvent: false,
    searchTerms: [],
    searchBarFocused: false,
    selectedSiteCd: null,
    showAlert: false,
    subgroup1Options: null,
    subgroup2Options: null,
    subgroup3Options: null,
    tenantOptions: null,
    tpaNameOptions: null,
    isSearchBarOpen: false,
    selectedSearchOptions: [],
    anchorEl: null
  };

  chartRequest = {};

  constructor(props, children) {
    super(props);
    store.subscribe(this.bstBarDispatchDone.bind(this));
    this.myRef = React.createRef();
  }

  bstBarDispatchDone = () => {
    if (
      store.getState().entities.User &&
      !isEmpty(store.getState().entities.User.itemsById)
    ) {
      if (this.props && this.props.message) {
        this.setState({
          ...this.state,
          showAlert: true
        });
      } else {
        this.setState({
          ...this.state,
          showAlert: false
        });
      }
    }
  };

  resetProfileSelections = (types) => {
    if (!types || types.length === 0) {
      return;
    }
    types.forEach((item) => {
      store.dispatch({
        type: item,
        payload: null
      });
    });
  };

  handleBrokerSelection = debounce((value) => {
    if (!value) {
      return value;
    }
    store.dispatch({
      type: UserTypes.BROKER_SET,
      payload: value.text
    });
  }, AppConfig.DEBOUNCE_WAIT_TIME);

  handleSiteSelection = debounce((value) => {
    trackClick(trackingTypes.TENANT, trackingActions.SWITCH, value?.code);
    store.dispatch(fetchData(value.code, this.props.currentApp));
  }, AppConfig.DEBOUNCE_WAIT_TIME);

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.processingEvent === true &&
      nextState.processingEvent === true
    ) {
      return false;
    }
    return true;
  }

  filterOptions = createFilterOptions({
    stringify: (option) => `${option.name} ${option.code}`
  });

  searchFilterOptions = createFilterOptions({
    stringify: (option) => `${option.searchTerm}`
  });

  searchOptionSelected = (searchOptionValue) => {
    store.dispatch({
      type: ReportTypes.OPEN_REPORT_VIEWER_REQUEST,
      reportUrl: searchOptionValue.reportSource
        ? searchOptionValue.reportSource
        : searchOptionValue.reptSource,
      category: searchOptionValue.category,
      title: searchOptionValue.displayText
    });
    this.setState({
      ...this.state,
      selectedSearchOptions: [searchOptionValue]
    });
  };

  render() {
    const { allBrokers, currentTenantCd, companies, classes } = this.props;

    this.chartRequest.tenant = this.props.currentTenantCd;

    return (
      <>
        <AppBar position="fixed" style={{ background: 'white' }}>
          <Grid container className={classes.container} ref={this.myRef}>
            <Grid item xs={2} className={classes.logoMenu}>
              <Grid container>
                <Grid item xs={8}>
                  <img
                    src={
                      AppConfig.FO_INITIAL_URL +
                      '/open-api/logo/tenant/' +
                      currentTenantCd
                    }
                    alt="BST"
                    onClick={() => {
                      this.props.history.push('/');
                    }}
                  />
                </Grid>
                <Grid item xs={2} />
              </Grid>
            </Grid>

            <Grid item xs={8} className={classes.searchPanel}>
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="nowrap"
                justifyContent="space-between"
                alignItems="center">
                {(() => {
                  if (AppConfig.HEADER_MENU_TYPE.RANGER === this.props.headerType) {
                    return (
                      <>
                        <Autocomplete
                          key="ranger-broker"
                          disableClearable
                          options={allBrokers}
                          getOptionLabel={(option) => option.name}
                          onChange={(e, value) => this.handleBrokerSelection(value)}
                          renderInput={(params) => <TextField {...params} label="Broker Name" variant="outlined" />}
                          className={classes.search}
                        />
                        <Autocomplete
                          key="ranger-tenant"
                          disableClearable
                          options={companies}
                          getOptionLabel={(option) => option.name}
                          onChange={(e, value) => this.handleSiteSelection(value)}
                          renderInput={(params) => <TextField {...params} label="Tenant Name/ID" variant="outlined" />}
                          filterOptions={this.filterOptions}
                          className={classes.search}
                          value={companies.find((c) => c.code === currentTenantCd) || null}
                        />
                      </>
                    );
                  } else if (
                    (AppConfig.HEADER_MENU_TYPE.STANDARD === this.props.headerType || AppConfig.HEADER_MENU_TYPE.FO === this.props.headerType) &&
                    this.props.showBstBarTenant === true
                  ) {
                    return (
                      <Autocomplete
                        key="tenant"
                        disableClearable
                        options={companies}
                        getOptionLabel={(option) => option.name}
                        onChange={(e, value) => this.handleSiteSelection(value)}
                        renderInput={(params) => <TextField {...params} label="Tenant Name/ID" variant="outlined" />}
                        filterOptions={this.filterOptions}
                        className={classes.search}
                        value={companies.find((c) => c.code === currentTenantCd) || null}
                      />
                    );
                  }
                })()}
                {AppConfig.HEADER_MENU_TYPE.FO === this.props.headerType && (
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="right"
                    alignItems="center">
                    <SearchIcon color="primary" />
                    <Autocomplete
                      key="fo-search"
                      disableClearable
                      style={{ width: 200 }}
                      options={this.props.menuSearchOptions}
                      getOptionLabel={(option) => option.displayText}
                      onChange={(e, value) => this.searchOptionSelected(value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Start Typing to Search"
                        />
                      )}
                      filterOptions={this.searchFilterOptions}
                    />
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box className={classes.profilePanel}>
                {Auth.getLoginMode() === Auth.LOGIN_MODE_BST && (
                  <Box
                    flexGrow={1}
                    height={1}
                    className={classes.profileHelper}>
                    <IconButton
                      id="expand-profile-menu"
                      onClick={() => {
                        axios
                          .get(AppConfig.FO_INITIAL_URL + `/external/zendesk`)
                          .then((res) => {
                            window.open(
                              'https://benefitsscience.zendesk.com/access/jwt?jwt=' +
                                res.data
                            );
                          })
                          .catch((err) => {});
                      }}>
                      <Help color="primary" variant="outline" />
                    </IconButton>
                  </Box>
                )}
                <Box className={classes.menuHeader}>
                  <AppNavigator />
                  <UserMenu topbarRef={this.myRef} />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </AppBar>
      </>
    );
  }
}

const mapStateToPropsSelector = createStructuredSelector({
  currentTenantCd: (state) => state.user.currentTenantCd,
  currentApp: (state) => state.user.currentApp,
  menuSearchOptions: (state) => state.fo.menuSearchOptions,
  companies: (state) => state.user.companies,
  headerType: (state) => state.user.headerType,
  allBrokers: (state) => state.user.allBrokers
});

export default connect(mapStateToPropsSelector)(
  withRouter(withStyles(styles)(BstBars))
);

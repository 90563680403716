import moment from 'moment-timezone';

export const FORMAT = (value, momentFormat) => {
  const parsed = moment(value);

  return parsed && parsed.isValid()
    ? parsed.format(momentFormat)
    : 'Invalid Date';
};

export const DEFAULT_FORMAT = (date) => {
  const parseDate = moment(date);

  return parseDate.isValid() === true ? parseDate.format('MM/DD/YYYY') : '';
};

import * as Types from './types';
import axios from 'axios';
import { AppConfig } from 'AppConfig';
import { store } from 'store/store';
import _ from 'lodash';
import * as AppActionTypes from 'store/actions/appstate';
import { getAllBrokers } from 'features/user/userActions';

export const SHOULD_REFRESH_REPORT_DEFAULT_SETTING_INIT_VALUE = 0;

export const getFoDetail = (tenantCd) => {
  return (dispatch) => {
    axios.post(AppConfig.FO_INITIAL_URL + '/user/fo-detail').then((res) => {
      dispatch({
        type: Types.FETCH_FO_DETAIL_SUCCESS,
        payload: { ...res.data, tenantCd: tenantCd }
      });
    });
  };
};

export const filterList = (
  searchValue,
  isPublic,
  data,
  flag,
  folders,
  folderKey
) => {
  const order = flag ? 'asc' : 'desc';
  const toDispatch = {
    searchValue: searchValue,
    isPublic: isPublic,
    data: data,
    order: order,
    folders: folders,
    folderKey: folderKey
  };
  return (dispatch) => {
    dispatch({
      type: Types.FILTER_SUCCESS,
      payload: toDispatch
    });
  };
};

export const changeOrder = (isPublic, flag, data, folders, folderKey) => {
  const toDispatch = {
    isPublic: isPublic,
    flag: flag,
    data: data,
    folders: folders,
    folderKey: folderKey
  };
  return (dispatch) => {
    dispatch({
      type: Types.SORT_SUCCESS,
      payload: toDispatch
    });
  };
};

export const getSaved = () => {
  return (dispatch) => {
    axios.get(AppConfig.FO_INITIAL_URL + '/saved-reports/').then((res) => {
      dispatch({
        type: Types.FETCH_SAVED_REPORT_SUCCESS,
        payload: res.data
      });
    });
  };
};

export const fetchMetricsByTenant = () => {
  return (dispatch) => {
    axios
      .get(AppConfig.FO_INITIAL_URL + `/metrics/findByTenant`)
      .then((res) => {
        dispatch({
          type: Types.FETCH_DEFAULT_METRIC_SUCCESS,
          payload: res.data
            .map((item) => ({
              ...item,
              order: parseInt(item.order)
            }))
            .sort((a, b) => a.order - b.order)
        });
      });
  };
};

export const saveMyMetric = (metrics) => {
  return (dispatch) => {
    axios
      .post(AppConfig.FO_INITIAL_URL + `/metrics/save`, metrics)
      .then((res) => {
        store.dispatch(fetchMetricsByUser());
      });
  };
};

const fetchMetricsByUser = () => {
  return (dispatch) => {
    axios.get(AppConfig.FO_INITIAL_URL + `/metrics`).then((res) => {
      dispatch({
        type: Types.FETCH_MY_METRIC_SUCCESS,
        payload: res.data
      });
    });
  };
};

export const dismissReport = (reportId) => {
  return (dispatch) => {
    axios
      .post(AppConfig.FO_INITIAL_URL + `/init/dismiss/id/${reportId}`)
      .then((res) => {
        store.dispatch(getFoDetail());
      });
  };
};

export const pinReport = (reportId) => {
  return (dispatch) => {
    axios
      .post(AppConfig.FO_INITIAL_URL + `/init/${reportId}/pin`)
      .then((res) => {
        store.dispatch(getFoDetail());
      });
  };
};

export const navigateToAdhoc = () => {
  return (dispatch) => {
    axios.get(AppConfig.FO_INITIAL_URL + `/tbl/url/adhoc`).then((res) => {
      window.open(res.data, '_tableau');
    });
  };
};

export const getStandardReport = () => {
  return (dispatch) => {
    axios.get(AppConfig.FO_INITIAL_URL + '/report/standard').then((res) => {
      dispatch({
        type: Types.FETCH_REPORT_STANDARD_SUCCESS,
        payload: res.data
      });
    });
  };
};

export const getSmartReport = () => {
  return (dispatch) => {
    axios.get(AppConfig.FO_INITIAL_URL + '/report/smart').then((res) => {
      dispatch({
        type: Types.FETCH_REPORT_SMART_SUCCESS,
        payload: res.data
      });
    });
  };
};


export const getExplorerReport = () => {
  return (dispatch) => {
    axios.get(AppConfig.FO_INITIAL_URL + '/report/explorer').then((res) => {
      dispatch({
        type: Types.FETCH_REPORT_EXPLORER_SUCCESS,
        payload: res.data
      });
    });
  };
};

export const getInspectionForTenant = () => {
  return (dispatch) => {
    axios.get(AppConfig.FO_INITIAL_URL + '/inspection/tenant').then((res) => {
      dispatch({
        type: Types.FETCH_INSPECTION_SUCCESS,
        payload: res.data
      });
    });
  };
};

const all = 'All';

const defaultFilter = (item, filterTarget, value) => {
  return item[filterTarget]
    ? item[filterTarget].toLowerCase().includes(value.toLowerCase())
    : false;
};

export const searchFilter = (
  data,
  filterTarget,
  value,
  setData,
  filter = defaultFilter
) => {
  if (Array.isArray(data)) {
    if (value === all) {
      setData(data);
    } else {
      const newData = _.filter(data, function (o) {
        value.toLowerCase();
        return filter(o, filterTarget, value);
      });
      setData(newData);
    }
  }
};

export const fetchReportTags = () => {
  return (dispatch) => {
    axios.get(AppConfig.FO_INITIAL_URL + '/tag').then((res) => {
      dispatch({
        type: Types.FETCH_REPORT_TAGS,
        payload: res.data
      });
    });
  };
};

export const syncReportsWithDefaultingSetting = (data) => {
  return (dispatch) => {
    axios
      .post(AppConfig.FO_INITIAL_URL + '/report-default-setting/sync', data)
      .then((res) => {
        dispatch({
          type: Types.REPORT_DEFAULT_SETTING_REFRESH
        });
      });
  };
};

export const saveReportefaultingSetting = (data) => {
  return (dispatch) => {
    axios
      .post(AppConfig.FO_INITIAL_URL + '/report-default-setting', data)
      .then((res) => {
        dispatch({
          type: Types.REPORT_DEFAULT_SETTING_REFRESH
        });
      });
  };
};

export const deleteDefaultingSettingById = (id) => {
  return (dispatch) => {
    axios
      .delete(AppConfig.FO_INITIAL_URL + `/report-default-setting/${id}`)
      .then((res) => {
        dispatch({
          type: Types.REPORT_DEFAULT_SETTING_REFRESH
        });
      });
  };
};

export const fetchAllDisplayText = () => {
  return (dispatch) => {
    axios.get(AppConfig.FO_INITIAL_URL + '/report/displaytext/all').then((res) => {

      dispatch({
        type: Types.FETCH_ALL_DISPLAY_TEXT_SUCCESS,
        payload: res.data.sort()
      });
    });
  };
};

export const fetchTenantReports = (dto) => {
  return (dispatch) => {
    return axios.post(AppConfig.FO_INITIAL_URL + '/report/get/by/tenantsAndDisplayText', dto).then((res) => {

      dispatch({
        type: Types.FETCH_REPORT_TO_DELETE_SUCCESS,
        payload: res.data
      });
    });
  };
};

export const deleteTenantReports = (dto) => {
  return (dispatch) => {
    axios
      .post(
        AppConfig.FO_INITIAL_URL + `/report/delete/by/tenantsAndDisplayText`,
        dto
      )
      .catch((error) => {
        dispatch({
          type: AppActionTypes.APPSTATE_SET_APP_ERROR,
          payload: error
        });
      });
  };
};

export const addReportToTenants = (data) => {
  return (dispatch) => {
    axios
      .post(AppConfig.GEMINI_URL + '/report/add/by/name', data)
      .then((res) => {
        dispatch({
          type: AppActionTypes.APP_REST_CALL_SUCESSED,
          payload:
            'Command is fired. Thumbnail generation is running behind the scenes, it may takes hours to generate all of them!'
        });
      });
  };
};

export const globalConfig = () => {
  return ( dispatch ) => {
    store.dispatch(getAllBrokers());
    axios
      .get(
        AppConfig.FO_INITIAL_URL + `/company-admin/by/active/${true}`,
        AppConfig.POST_HEADERS
      )
      .then((res) => {
        let allCompanies = res?.data.sort((a, b) =>
          a.name?.toLowerCase() < b.name?.toLowerCase() ? -1 : 0
        );
        dispatch({
          type: Types.OPEN_GLOBAL_CONFIG_REQUEST,
          payload: {allCompanies: allCompanies }
        });
      });
  };
};

export const initTenantReports = (data) => {
  return ( dispatch ) => {
    let companyCodes = data.companies.map( ( c ) => c.code );
    axios
      .post(
        AppConfig.GEMINI_URL + `/report/sync/tenants/mode/${data.mode}`,
        companyCodes
      )
      .catch((error) => {
        throw new Error('Internal Server Error');
      });
  };
};
